.login-page-container {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-image: url(../assets/img/login_background.jpg);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  overflow-x: hidden;

  .overlay {
    background-image: url(../assets/img/film_overlay.jpg);
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.25;
  }

  .container {
    height: 80%;
    padding-top: 30px;
  }

  .logo-and-login-text {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 1%;

    .logo {
      width: 180px;
      height: 119px;
    }
  }

  .login-form {
    margin-left: auto;
    margin-right: auto;
    width: 384px;
    height: 362px;
    min-height: 362px;
    padding-top: 40px;
    padding-bottom: 40px;
    background: #FFF7F1;
    border: 3px solid #000000;
    box-shadow: 0 4px 7px 3px rgba(0, 0, 0, 0.25);
    border-radius: 36px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    > div {
      width: 320px;
    }

    label {
      color: #000000;
      font-family: 'Futura', Arial, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
    }

    .MuiInputBase-root {
      height: 48px;
      box-sizing: border-box;
      border: 2px solid #000000 !important;
      border-radius: 7px;
      background-color: #FFF7F1;

      input {
        height: 48px;
        padding: 1px 2px;
        font-family: 'Futura', Arial, sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 140%;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active{
          -webkit-background-clip: text;
          transition: background-color 5000s ease-in-out 0s;
          box-shadow: inset 0 0 20px 20px #23232329;
        }
      }
    }
  }

  .login-button {
    width: 320px;
    height: 48px;
    background: #000000;
    border-radius: 11px;

    &:hover {
      transition: all 0.3s ease;
      background-color: #DD75F2;
      border-color: #DD75F2;
    }
  }

  .login-button-subfields {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .subfields-text {
      color: #000000;
      font-family: 'Futura', Arial, sans-serif;
      font-style: normal;
      font-weight: 200;
      font-size: 10px;
      line-height: 140%;
      text-decoration: underline;

      a {
        color: #000000;

        &:hover {
          transition: all 0.3s ease;
          color: #DD75F2;
        }
      }
    }
  }

  .above-footer {
    height: 2%;
    position: fixed;
    width: 100%;
    bottom: calc(4%);
    background-image: url(../assets/img/colorbar.jpg);
    background-size: cover;
  }

  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 4%;
    background-color: black;

    .support-div {
      height: 100%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      line-height: 100%;

      .support-link {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: #FFF7F1;
        font-family: 'Futura', Arial, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        text-decoration: none;
      }
    }
  }
}
