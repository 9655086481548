@import 'src/theme/colors';
@import 'src/theme/styles';

$sceneTabHeaders: 38px;

.GenerateSides {
  display: flex;
  width: 100%;

  .mainSection {
    display: flex;
    width: 100%;
    margin: 0px;
    margin-top: 15px;

    .clearSceneSelectionBtn {
      cursor: pointer;
      width: fit-content;
      float: right;

      .clearSceneSelectionText {
        vertical-align: middle;
        display: inline;
        margin: 0 10px 0 3px;
        color: $BlackGrey
      }

      .autoRenewIcon {
        vertical-align: middle;
        display: inline;
      }

      .autoRenewIcon path {
        color: $BlackGrey;
      }
    }
  }
}

.sceneTabItem {
  padding: 5px 0;
  color: $LightBlack;
}

.sceneTabItem:hover {
  background-color: $WhiteGrey;
  cursor: pointer;
}

.sceneTabItem:not(:last-child) {
  border-bottom: 1px solid $WhiteGrey;
}

.sceneTabItemText {
  text-align: left;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  line-height: 150%;
  color: $BlackGrey;
}

.checkboxGrid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.locationGroupHeader {
  padding: 10px 0;
  background-color: $WhiteGrey;
}

.locationGroupText {
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  color: $BlackGrey;
  text-transform: capitalize;
}

.sceneTabHeader {
  .tabContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: $sceneTabHeaders;
  }

  .flexContainer {
    height: $sceneTabHeaders;
  }

  .tabIndicator {
    display: none;
  }

  .tabRoot {
    font-weight: 700;
    text-transform: capitalize;
    font-size: 0.85rem;
    color: $BorderBlack;
    border: none;
  }

  .selectedTab {
    color: $BorderBlack !important;
    font-weight: 700;
    border: 1px solid $BorderGrey;
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  }

  .tabClose {
    position: absolute;
    right: -5px;
    top: -5px;
    color: $IconGrey;
    display: none;
  }

  .tabRoot:hover {
    .tabClose {
      display: none;
    }
  }

  .selectedTab:hover {
    .tabClose {
      display: flex;
    }
  }
}
