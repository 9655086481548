@import './../../theme/colors';

.tabPanel {
  color: $LightBlack;
}

.formTitle {
  color: $BorderBlack;
  font-size: 24px;
}

.fontWeight700 {
  font-weight: 700;
}

.fontWeight400 {
  font-weight: 400;
}

.settingTitle {
  margin-top: 16px;
  margin-bottom: 0;
}

.categoryTitle {
  font-weight: bold !important;
  font-size: 0.95rem !important;
  color: $LightBlack !important;
}

div.description {
  color: gray;
  margin-top: 16px;
}