$MainBlack: #000000;
$LightBlack: rgba(0, 0, 0, 0.54);
$BlackGrey: rgba(0, 0, 0, 0.64);
$TertiaryGrey: rgba(68, 68, 68, 1);
$BorderGrey: rgba(108, 109, 107, 0.34);
$BorderBlack: #222222;
$ShadowBlack: rgba(0, 0, 0, 0.15);
$TempBlack: #1A2027;
$Backdrop: rgba(88, 88, 88, 0.08);
$ChipColor: #444444;
$Silver: #CCCCCC;
$IconGrey: #9E9E9E;
$MainWhite: #FFFFFF;
$WhiteGrey: #F2F0F0;
$LightGrey: #CDCDCD;
$WhiteGreen: #DFF7DF;
$CrooglooGreen: #01AD25;
$DarkerCroolooGreen: #008D1E;
$LimeGreen: #24C250;
$LightGreen: #DFF7DF;
$BackgroundGreen: rgba(1, 173, 37, 0.1);
$ToastPink: #FD0055;
$MainRed: #FF0000;
$ErrorRed: #F04937;
$MenuHover: #F6DAC4;
$SideBarColor: #FFF7F1;