.UserSettings {
  padding-left: 50px;
  padding-top: 40px;
  width: 100%;
  overflow-y: auto;

  hr {
    border-top: 1px;
  }
  .indicator {
    background-color: rgba(5,174,52,255);
  }

  .scroller {
    height: 42px;
  }

  .tabPanelContainer {
    padding-left: 16px;
  }

  input[type='file'] {
    visibility: hidden;
  }
}