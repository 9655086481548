@import '../../theme/colors';

.SideBar {
  .Selected {
    font-weight: bold;
  }

  .NavListButton {
    font-size: 0.95rem;
    line-height: 23px;
    color: #000;
  }

  .SideBarButton {
    padding: 4px 0px;
    color: $MainBlack;
    font-size: 1rem;
    font-weight: 500;
    line-height: 19px;
    border-radius: 40px;
    height: 48px;
  }

  .SideBarButton:hover {
    background-color: $SideBarColor;
  }

  .SelectedBarButton {
    background-color: $MenuHover;
  }

  .SelectedBarButton:hover {
    background-color: $MenuHover;
  }

  .SideBarIcon {
    min-width: 48px;
    display: flex;
    justify-content: center;
    color: $MainBlack
  }

  .SideBarListItem {
    color: $MainBlack;
    font-weight: 500;
    line-height: 19px;
    padding: 5px 10px;
  }

  .SideBarListItem:hover {
    background-color: $SideBarColor;
  }
}