@import 'src/theme/colors';
.SceneListItem {
  .dragHandle {
    color: gray;
    cursor: move;
  }

  .sceneContent {
    display: flex;
    flex-direction: row;
  }

  .sceneDescription {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex-grow: 1;
    font-size: 0.875rem;
    line-height: 150%;
    font-weight: 400;
    color: $BlackGrey;
  }

  .copyIcon {
    color: $BlackGrey;
  }

  .copyIcon:hover {
    cursor: pointer;
    color: $CrooglooGreen;
  }

  .deleteIcon {
    opacity: 0;
  }

  .colorChip {
    background-color: $WhiteGreen;
    color: $BlackGrey;
    border-radius: 16px;
    padding: 0px 12px;
    margin-left: 10px;
  }

  .iconColumn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}

.SceneListItem:hover {
  cursor: pointer;
  background-color: $WhiteGrey;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);

  .deleteIcon {
    opacity: 1;
  }

  .deleteIcon:hover {
    cursor: pointer;
  }

  .deleteIcon:hover path {
    fill: $ToastPink;
  }
}