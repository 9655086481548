@import 'src/theme/styles';
@import 'src/theme/colors';

.header {
  .appBar {
    height: $headerHeight;
    border-bottom: 1px solid rgba(108, 109, 107, 0.34);
  }

  .appBarWithBackground {
    height: $headerHeight;
  }

  .toolBar {
    color: #222;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: end;
    min-height: $headerHeight;
  }

  .headerLogo {
    flex: 1;
    display: flex;
    justify-content: flex-start;
  }

  .uploadWrap {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}

.navItem {
  line-height: 20px !important;
  color: $BorderBlack !important;
}

.navLink {
  text-decoration: unset;
}