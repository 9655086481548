@import 'src/theme/colors';
@import 'src/theme/styles';

.EmailDrawerTab {
  *:focus:not(:focus-visible) {
    outline: none;
  }
}

.messageItemTable {
  display: table;
  width: 100%;
  table-layout: fixed;
  font-size: 1rem;
  padding-top: 4px;

  .messageItemRow {
    display: flex;
    align-items: center;
  }

  .subjectContainer {
    flex: 0 0 65%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
  }

  & > div {
    display: table-row;
    width: 100%;

    & > div {
      display: table-cell;
      text-align: left;

      &.selectedCellData {
        padding-left: 3px;
        padding-bottom: 5px;

        div {
          display: inline-block;

          &.messageActionBtn {
            cursor: pointer;
          }
        }
      }

      &.dateCell {
        font-size: 0.85rem;
        color: #CDCDCD;
        padding-left: 5px;
        padding-right: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 0 0 25%;
      }

      &.attachmentCell {
        display: flex;
        flex: 0 0 10%;
        justify-content: center;
        align-items: center;
        font-size: 9px;
      }
    }
  }
}

.dataGridHeader {
  font-weight: bold;
}

.outboxSchedule {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .dataTimeText {
    width: 30%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.paginationPanel {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 8px;
  position: sticky;
  bottom: 0;
  background-color: $MainWhite;
  height: $paginationDrawerHeight;

  .navItem {
    width: 40px;
    cursor: pointer;
    font-weight: 500;
    color: $BorderBlack;

    svg {
      font-size: 1rem;
    }

    &.disabled {
      color: $LightGrey;
      cursor: default;
    }
  }

  .navLabel {
    color: $BorderBlack;
    font-size: 0.85rem;
  }

  .hideLabel {
    display: none;
  }
}

.drawerTab {
  height: calc(100vh - #{$paginationDrawerHeight} - #{$headerHeight} - #{$chipDropdownHeight} - 20px);
  width: 100%;
}
