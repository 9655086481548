@import 'src/theme/colors';

.UploadSteps {

  .stepperGrid {
    display: flex;
    justify-content: center;
  }

  .stepper {
    width: 50%;
  }

  .stepTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 29px;
    display: flex;
    justify-content: center;
  }

  .stepMessage {
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: $BorderBlack;
    display: flex;
    justify-content: center;
    text-decoration: underline;
  }

  .stepComponent {
    padding: 10px 0px;
    text-align: left;
  }
}

.stepIcon {
  color: $BlackGrey;
}

.completedStepIcon {
  color: $CrooglooGreen !important;
}

.activeStepIcon {
  color: $CrooglooGreen !important;
}