@import "src/theme/colors";

.TabHeaders {

  .tabContainer {
    padding-left: 0px;
  }

  .tabIndicator {
    background-color: $LimeGreen;
  }

  .headersSpaced {
    justify-content: space-around;
  }

  .tabRoot {
    font-weight: normal;
    text-transform: capitalize;
    color: $BorderBlack;
  }

  .centered {
    flex-basis: 50%;
    flex-grow: 1;
    justify-content: start;
    flex-direction: row;
  }

  .selectedTab {
    color: $BorderBlack !important;
    font-weight: 700;
  }
}
