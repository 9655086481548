@import 'src/theme/styles';

.DocumentLists {

  .documentList {
    width: 100%;
    height: calc(100vh - #{$headerHeight} - #{$chipDropdownHeight});
  }

  .sortDropDown {
    text-align: left;
    padding: 20px 0px 5px 10px;
    width: fit-content;
  }
}
