@import '/src/theme/colors';

.callTime {
  box-sizing: border-box;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid rgba(108, 109, 107, 0.34);
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .productionName {
    font-weight: 700;
    font-size: 2rem;
    line-height: 50px;
    color: $BorderBlack;
  }

  .welcomeMessage {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 30px;
    color: $BorderBlack;
  }
}

.buttonPanel {
  margin-top: 14px;
  display: flex;
  justify-content: space-around;
  width: 563px;

  button {
    width: 222px;
    font-weight: bold;
  }
}


