@import '../../theme/colors.scss';

.FileUpload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.fileDraggingActive {
  background: $BackgroundGreen;
  border: 2px solid $CrooglooGreen;
}
