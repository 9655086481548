@import 'src/theme/colors';

.emptyList {
    color: $BorderBlack;
}

.emptyListTitle {
    font-size: 4em;
    font-weight: bold;
    color: $BorderBlack;
    text-transform: uppercase;
    font-family: 'SchmalfetteCP', sans-serif;
}

.subLabel {
    text-align: left;
    font-size: 1.4em;
    line-height: 1.2em;
    width: 303px;
    font-family: 'Courier New', sans-serif;
    font-weight: bold;
}