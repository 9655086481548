.textInput {
  height: 40px;
  font-size: 1rem !important;
}

@media screen and (max-width: 992px) {
  .adjustInput {
    height: 30px;
    font-size: 0.875rem !important;
  }
}