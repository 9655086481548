@import '../../theme/colors.scss';

.Upload {
  display: flex;
  justify-content: center;
  margin: 4rem 3.25rem;
  padding: 1rem;
  width: 100%;

  .uploadBox {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
    width: 900px;
    border: 1px solid $BorderGrey;
  }

  .uploadLabel {
    font-size: 1.2rem;
    font-weight: 400;
  }
}