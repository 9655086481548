@import './colors';
.actionButtons {
  display: flex;
}

.actionBtnContainer {
  text-align: left;
  padding-left: 10px;
  width: fit-content;
  cursor: pointer;
  color: $BlackGrey;
  display: block;
  text-decoration: none;

  & > button {
    color: $MainWhite;
  }
  & > div {
    vertical-align: middle;
    display: inline;
  }
}
