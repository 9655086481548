@import 'src/theme/colors';
.Watermark {
  .fileChipArea {
    padding-top: 15px;
    padding-bottom: 5px;

    ul {
      padding: 0;
    }

    li {
      margin: 0 5px 10px 0;
      width: calc(50% - 10px);

      div {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
    }
  }

  .actionBtn {
    display: inline;
    vertical-align: middle;
    cursor: pointer;
    font-size: 0.875rem;

    &:first-child {
      margin-right: 20px;
    }

    & > * {
      display: inline;
      vertical-align: middle;
    }
  }

  .chipContainer {
    padding: 5px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;

    .chip {
      font-weight: 600;
      font-size: 0.75rem;
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14);
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
    }

    .selectedChip {
      font-weight: 600;
      font-size: 0.75rem;
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14);
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
      background-color: $CrooglooGreen;
      color: $MainWhite;
    }

    .selectedChip:hover {
      background-color: $DarkerCroolooGreen;
    }
  }

  .chipSpacingContainer {
    justify-content: space-between;
    max-width: 80%;
  }

  .selectedChipsContainer {
    padding: 5px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .paperSectionTitle {
    font-size: 0.95rem;
    margin: 15px 0 5px 3px;
    color: black;
  }

  .radioGroup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .radioLabel {
    font-size: 0.9rem;
  }
}