@import 'src/theme/colors';

.DashboardFolders {
  box-sizing: border-box;
  background: $MainWhite;
  border: 1px solid rgba(108, 109, 107, 0.34);
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  flex-basis: 50%;
  flex-grow: 1;

  .tabHeader {
    padding: 5px 10px;
  }
}