@import 'src/theme/colors';

.circularProgress {
  position: fixed;
  z-index: 1500;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  color: $CrooglooGreen !important;
}

.linearPrimary {
  background-color: $CrooglooGreen !important;
}

.barLinearPrimary {
  background-color: $WhiteGreen !important;
}