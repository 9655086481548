html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    position: relative;
    overflow-x: hidden;
}

a {
    color: #545454;
}

a:hover {
    transition: all 0.3s ease;
    color: #DD75F2; 
}


.flex_row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 2fr;
    grid-column-gap: 30px;
    max-height: 100px;
}

#launch_production {
    margin-top: 26px;
    height: 50px;
    font-size: 1em;
}

#overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000;
    opacity: 0.65;
    z-index: 2;
    cursor: pointer;
  }

#loading{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
 }

.anchor_no_underline {
    text-decoration: none;
    color: #FFF7F1 !important; 
}

.login_no_underline {
    text-decoration: none;
}

.login_no_underline:hover {
    transition: all 0.3s ease;
    color: #FFF7F1 !important;
}

.anchor_no_underline:hover {
    transition: all 0.3s ease;
    color: #DD75F2; 
}

.content_parent {
    background-size: cover;
}

.container {
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
}

.form_container {
    display: flex;
    flex-direction: column;
}

.top_section {
    vertical-align: top;
    width: 100%;
    height: 140px;
    /* background-image: url('/src/assets/img/film_grain_texture_2k_black.png'); */
    background-size: cover;
}

.header_nav {
    height: 30%;
    padding-top: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.logo_prod {
    margin-left: 10%
}

.login_menu {
    display: flex;
    flex-direction: row;
    margin-right: 8%;
    gap: 12px;
}

.login_button {
    height: 56px;
    width: 207px;
    box-sizing: border-box;
    border: 2px solid #FFF7F1;
    border-radius: 11px;
}

.login_button:hover {
    transition: all 0.3s ease;
    background-color: #DD75F2;
    border-color: #DD75F2;
}

.user_quote {
    text-transform: uppercase;
}

.user_quote_name {
    padding-top: 18px;
}

.cp_content {
    vertical-align: top;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
    position: absolute;
    width: 100%
}

.production_header {
    font-family: 'Inter';
    font-size: 2rem;
    text-align: left;
    font-weight: 700;
    padding-left: 10px;
}

.info_container {
    border: 1px solid black;
    border-radius: 16px;
    padding: 32px;
    margin-top: 3%;
    min-width: 324px;
}

.package_info {
    align-items: center;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#package_info {
    color: #01AA25;
    line-height: normal;
    font-weight: bold;
    font-size: 1.35em;
}

.package {
    color: black !important;
    font-size: 1.75em !important;
}

.separator {
    border: none;
    margin-top: 35px;
}

.feedback_box {
    text-align: center;
    font-family: 'Arial';
    font-weight: 900;
    margin-top: 55px;
    font-size: 1.7em;
    font-style: italic;
    line-height: 1.2em;
}

.feedback_signature_box {
    text-align: center;
    font-family: 'Courier';
    margin-top: 16px;
    font-size: 1.1em;
}

.included_in_every_package_container {
    margin-top: 0;
}

.included_in_every_package {
    margin-bottom: 20px;
}

.includes {
    margin-top: 5px;
    margin-bottom: 5px;
}

.registration_info {
    width: 30%;
    align-items: center;
}

.registration_fields_div {
    display: flex;
    flex-direction: row;
    padding-top: 16px;
    justify-content: flex-start;
}

.registration_fields {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 6px;
}

.input_div {
    font-size: 16px;
    padding: 16px 0 16px 0;
}

.futura_input_field {
    font-family: 'Futura', Arial, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 140%;
}

.input_field_short {
    height: 90% !important;
    width: 100% !important;
    margin-top: 8px;
    padding-top: 4px;
    box-sizing: border-box;
    border: 2px solid #000000;
    border-radius: 11px;
}

.input_field_long{
    height: 90% !important;
    width: 100% !important;
    margin-top: 8px;
    padding-top: 4px;
    box-sizing: border-box;
    border: 2px solid #000000;
    border-radius: 11px;
}

.request_demo_button {
    margin-top: 12px;
    background: #FFF7F1;
    border-radius: 11px;
    height: 61px;
    width: 80%;
}

.request_demo_button:hover {
    transition: all 0.3s ease;
    background-color: #DD75F2;
    border-color: #DD75F2;
}

.flex_center_container {
    display: flex;
    justify-content: center;
}

.flex_start_container {
  display: flex;
  justify-content: flex-start;
}

.launch_production_button {
    background: #222222;
    border-radius: 11px;
    height: 61px;
    width: 100%;
}

.launch_production_button:hover {
    transition: all 0.3s ease;
    background-color: #DD75F2;
    border-color: #DD75F2;
}

.info_contact {
    padding-top: 2%;
}

.package_container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 40px;
}

.black_line {
    width: 100%;
    height: 0px;
    border: 1px solid #000000;
    background-color: #000000
}

.bottom_section {
    width: 100%;
    padding: 0;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 40px;
    z-index: 1000;
}

.white_line {
    width: 100%;
    height: 0px;
    margin: 36px 0 36px 0;
    border: 1px solid #FFF7F1;
    background-color: #FFF7F1
}

.bottom_section_vertical_flex_container {
    display: flex;
    flex-direction: column;
    padding-bottom: 48px;
}

.bottom_section_horizontal_flex_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.packages_container {
    display: flex;
    flex-direction: column;
}

.about_us_resources_container {
    display: flex;
    flex-direction: column;
}

.packages_container div {
    padding: 2px 0 2px 0;
}

.about_us_resources_container div {
    padding: 2px 0 2px 0;
}

.left_side_bottom_container {
    display: flex;
    flex-direction: row;
    gap: 100px;
}

.features_security_container {
    padding-top: 12px;
}

.right_side_bottom_container {
    display: flex;
    flex-direction: column;
}

.chat_container {
    display: flex;
    flex-direction: column;
    text-align: right;
}

.socials_container {
    display: flex;
    flex-direction: row;
    gap: 14px;
    padding-top: 72px;
}

.footer {
    width: 100%;
    height: 72px;
    background: #E97BFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.footer_element {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto;
}

.footer_element div {
    margin-left: 12px;
}

a.security_and_privacy:hover { 
    color: #000000; 
}

.text_align_center {
    text-align: center;
    padding: 12px;
}

.text_futura {
    font-family: 'Futura', Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
}

.text_futura_original {
    font-family: 'Futura', Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
}

.text_futura_larger {
    font-family: 'Futura', Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
}

.text_futura_26 {
    font-family: 'Futura', Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 140%;
}

.text_schmalfette_big {
    font-family: 'SchmalfetteCP';
    font-style: normal;
    font-weight: 400;
    font-size: 46px;
    line-height: 100%;
}

.text_futura_small_500 {
    font-family: 'Futura', Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
}

.text_futura_small_pt {
    font-family: 'Futura', Arial, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
}

.text_futura_small_pt_original {
    font-family: 'Futura', Arial, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 140%;
}

.text_white {
    color: #FFF7F1;
}

.text_black {
    color: #000000;
}

.text_copyright {
    color: #DD75F2;
    font-size: 16px !important;
}

.text_monospace {
    font-family: 'Courier';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
}

.ensemble_image img {
    vertical-align: top;
    width: 80%;
    margin: 0 0 0 9%;
    padding-bottom: 2%;
}

/* tooltips for showing what package includes */
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 3px dotted black;
}

.tooltip .tooltip_text {
    visibility: hidden;
    width: 430px;
    background-color: #FFFFFF;
    color: black;
    padding: 15px 0 15px 20px;
    border-radius: 12px;
    border-style: solid;
    border-width: 4px;
    line-height: 150%;
   
    /* Position the tooltip text to be above the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 25%;
    margin-left: -60px;
}
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltip_text {
    visibility: visible;
}

/* Fonts */

@font-face {
    font-family: 'SchmalfetteCP';
    src: url(/src/assets/fonts/SchmalfetteCP.otf);
    font-weight: normal;
    font-style: normal; 
}

/* Overloaded swal css */ 

/* needed to fix swal positioning */
body.swal2-height-auto {
    all: initial;
}

.swal2-popup {
    font-family: 'Futura', Arial, sans-serif !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 170% !important;
}

.image-container {
    width: 100%; /* Set container width to 100% */
    max-width: 2652px; /* Set max width to maintain proportions */
    margin: 0 auto; /* Center the container horizontally */
}

.image-container .image {
    width: 100%; /* Set image width to fill its container */
    height: auto; /* Automatically adjust height to maintain aspect ratio */
}

@media (max-width: 1400px) {

    .image-container .image {
        display: none;
    }

    .content_parent {
        min-height: 1000px;
        background-size: contain;
        /* background-image: url('/src/assets/img/create_prod_bg.jpg'); */
    }

}

@media (max-width: 1080px) {
    .info_container {
        /*margin: 0;*/
    }

    .image-container .image {
        display: none;
    }

    .content_parent {
        min-height: 2090px;
        background-size: contain;
        /* background-image: url('/src/assets/img/create_prod_bg_vertical.jpg'); */
    }

    .feedback_box {
        font-size: 1.4em;
    }

    .cp_content {
        margin-top: 42px;
    }
}

