@import '../../theme/colors.scss';

.DistributionReport {
  width: 100%;

  .viewButton {
    display: flex;
    align-self: flex-start;
    font-weight: 700;
  }

  .reportBox {
    padding: 0;
  }

  .totalSentLabel {
    margin-block-start: 0.4rem;
    font-size: 1.4rem;
    margin-block-end: 0;
    color: rgb(60, 60, 60);
  }

  .dataGrid {
    border: none;

    .MuiDataGrid-virtualScroller::-webkit-scrollbar {
      width: '0.4em'
    }

    .MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
      box-shadow: 'inset 0 0 6px rgba(0,0,0,0.00)';
      -webkit-box-shadow: 'inset 0 0 6px rgba(0,0,0,0.00)';
    }

    ::-webkit-scrollbar-thumb {
      background-color: 'rgba(0,0,0,.1)';
      outline: '1px solid slategrey';
    }
  }

  .dataGridHeader {
    font-weight: bold;
    text-transform: capitalize;
  }

  div.statusCircle {
    border-radius: 13px; // should always remain half of width and height
    width: 25px; // should always be equal to height
    height: 25px; // should always be equal to width
    border: 0.5px solid rgba(0,0,0,0.1);
    margin-left: 6px;
  }

  .messageStatusPanel {
    text-align: center;
    width: 100%;
  }

  .messageData {
    font-size: 1rem;
    font-weight: bold;
  }

  .successMessages {
    color: $CrooglooGreen;
  }

  .failMessages {
    color: $MainRed;
  }
}
