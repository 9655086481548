@import 'src/theme/colors';

.dialogRoot {
  .dialogPaper {
    width: 100%;
    max-height: 100%;
    border-radius: 10px;
  }

  .dialogGeneralWidth {
    width: 538px;
  }

  .dialogBackdrop {
    opacity: 1;
    backdrop-filter: blur(4px);
    background: $Backdrop;
  }

  .dialogTitle {
    margin: 2px;
  }

  .dialogContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }

  .dialogCloseButton {
    position: absolute;
    right: 8px;
    top: 8px;
    color: $BlackGrey;
  }

  .dialogActionsEnd {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 20px;
    padding-right: 22px;
  }

  .dialogActionsCenter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 20px;
  }

  .errorField {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: $ErrorRed;
  }

  .contentText {
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    color: $LightBlack;
    text-overflow: ellipsis;
    overflow-x: hidden;
    word-wrap: break-word;
  }

  .cancelButton {
    font-weight: 550;
    line-height: 20px;
    color: $TertiaryGrey;
  }

  .dialogIcon {
    color: $LightBlack;
    width: 70px;
    height: 3rem;
  }

  .warningIcon {
    width: 140px;
    height: 7rem;
    margin-left: 55px;
  }

  .headerGrid {
    padding-top: 0 !important;
  }

  .successIcon {
    color: $LimeGreen;
    width: 70px;
    height: 4rem;
  }
}