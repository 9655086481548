@import './../../theme/colors';
@import './../../theme/styles';

.tabPanel {
  color: $LightBlack;
}

.formTitle {
  color: $BorderBlack;
  font-size: 24px;
}

.fontWeight700 {
  font-weight: 700;
}

.fontWeight400 {
  font-weight: 400;
}

.settingTitle {
  margin-top: 16px;
  margin-bottom: 0;
}

div.description {
  color: gray;
  margin-top: 16px;
}

.groupList {
  width: 100%;
  height: calc(100vh - #{$headerHeight});
  padding-top: 0 !important;
}

.tableHeader {
  text-align: left !important;
}

.tableRow {
  th, td {
    font-size: 1rem;
    color: $LightBlack;
  }
}