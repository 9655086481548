.LegendListItem {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  div.circle {
    div {
      border-radius: 13px; // should always remain half of width and height
      width: 25px; // should always be equal to height
      height: 25px; // should always be equal to width
      border: 0.5px solid rgba(0,0,0,0.1);
    }
  }

  div.label {
    padding-left: 10px;
    color: rgba(0,0,0,0.9);
    font-weight: bold;
  }

  div.label, div.circle {
    display: table-cell;
    vertical-align: middle;
  }
}

.LegendListItem:hover {
  cursor: pointer;
}