@import 'src/theme/colors';
@import 'src/theme/styles';

.tabPanel {
  color: $LightBlack;
  margin-top: 20px;
}

.searchBar {
  display: flex;
  justify-content: flex-end;
}

.sideBarList {
  width: 100%;
  height: calc(100vh - #{$headerHeight} - #{$tabHeaderHeight});
}