@import 'src/theme/colors';

.DistributionHistory {}

.DistributionHistoryContent {
  .composeBoxEmpty {
    border: 1px solid $BorderGrey;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 16px;
    margin-top: 64px;
    margin-left: 64px;
    margin-right: 64px;
  }
}
