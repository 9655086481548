.FileWatermark {

  .fileLine {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .fileLineItems {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-bottom: 10px;
  }
}

.watermarkListItem {
  align-items: center;
}