@import 'src/theme/colors';

.ContactListingsTable {
  display: flex;
  justify-content: center;
  flex: 1;
}

.tableBox {
  border: 1px solid rgba(108, 109, 107, 0.34);
  border-radius: 5px;
  width: inherit;
}

.tableHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.headerRow {
  border-bottom: 1px solid rgba(108, 109, 107, 0.34);
  display: flex;
}

.headerContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-right: 4px;
}

.headerCell {
  color: $BorderBlack;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 150%;
}

.detailsHeaderCell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.tableRow {
  border-bottom: 1px solid rgba(108, 109, 107, 0.34);
  display: flex;
  flex-direction: row;
}

.tableRow:hover {
  background: $WhiteGrey;
  cursor: pointer;
  border-top: 1px solid rgba(108, 109, 107, 0.34);
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
  z-index: 100;
}

.listHeader {
  padding: 16px 16px;
  font-weight: bold;
}

.tableCell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: $LightBlack;
  font-weight: 400;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 150%;
  overflow-wrap: break-word;
  white-space: initial;
}

.nameCell {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.nameBox {
  font-weight: 700;
  text-align: left;
  color: $BlackGrey;
  width: 95%;
}

.jobTitleBox {
  text-align: left;
  width: 95%;
}

.castBox {
  font-weight: 700;
  text-align: left;
  color: $BlackGrey;
}

.listsBox {
  display: flex;
  text-align: left;
  width: 95%;
}

.listsContent {
  display: flex;
  align-items: center;
  min-height: 80px;
  height: auto;
  font-weight: 400;
  font-size: 0.875rem;
}

.tableRow:hover {
  background: $WhiteGrey;
  cursor: pointer;
}

.checkBoxCellSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.jobTitle {
  min-height: 16px;
}

.moreIcon {
  visibility:hidden;
}

.tableRow:hover .moreIcon {
  visibility:visible;
}

.dummyBox
{
  display: block;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 400;
  font-style: normal;
  font-size: 0.875rem;
  border: none;
  outline: none;
  background-color: inherit;

  &:hover,
  &:active,
  &:focus {
    outline: 0;
    border: none;
  }
}

.editableBox {
  color: rgba(0, 0, 0, 0.54);
  font-weight: 500;
  font-style: normal;
  font-size: 1rem;
}

.phoneCell {
  display: flex;
  flex-direction: column;
}

.phoneData {
  display: flex;
  flex-direction: row;
}

.overflowBox {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.emailBox {
  text-align: left;
  width: 95%;
}

@media screen and (min-width: 1500px) {
  .headerCell {
    font-size: 0.9rem;
  }

  .tableCell {
    font-size: 0.9rem;
  }

  .dummyBox {
    font-size: 0.9rem;
  }

  .editableBox {
    font-size: 0.9rem;
  }
}