@import "src/theme/colors";

.UploadFlow {
  text-align: center;

  .UploadFlowPaper {
    max-width: 535px;
    max-height: calc(100vh - 100px);
    overflow-y: hidden;
  }

  .dialogIcon {
    color: $BlackGrey;
    width: 70px;
    height: 3rem;
  }

  .header {
    color: $BorderBlack;
    font-size: 1.5rem;
    font-weight: 700;
  }
}