.scrollbar {
  padding-top: 0 !important;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0.4em;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    min-height: 0.5em;
    background: #F2F0F0;
    border-radius: 50px;

    &:hover {
      background: #CDCDCD;
      cursor: pointer;
    }
    &:active {
      background: #CDCDCD;
      opacity: 0.5;
      cursor: pointer;
    }
  }
  &::-webkit-scrollbar-track-piece:end {
    background: transparent;
  }
  &::-webkit-scrollbar-track-piece:start {
    background: transparent;
  }
}
