@import 'src/theme/colors';

.dashboard {
  height: fit-content;
  overflow-y: hidden;
}

.dashboardContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.lowerSection {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.footer {
/*  height: 170px;
  background-image: url(../../assets/img/crooglooFooter.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;*/
}

@media (min-width: 1200px) {
  .lowerSection {
    flex-direction: row;
    align-items: stretch;
  }
}
