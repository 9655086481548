@import 'src/theme/colors';
.Documents {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  .backButtonRow {
    display: flex;
    justify-content: flex-start;
  }

  .backButtonContainer {
    display: flex;
    flex-direction: row;
    min-width: 100%;
    padding-left: 15px;
    padding-top: 10px;
  }

  .backButton {
    color: $BorderBlack;
    font-size: 1rem;
    padding: 8px 4px;
    font-weight: 600;
  }

  .backButton:hover {
    cursor: default;
  }

  .backButtonHover:hover {
    color: $CrooglooGreen;
    cursor: pointer;
  }


  .documentButtons {
    margin: 10px 10px 20px 10px;
    display: flex;
  }

  .documentTable {
    display: flex;
    margin: 0px 10px;
  }
}