@import 'src/theme/colors';
@import 'src/theme/styles';

.ScriptListDrawerTabs {}

.actionBtnContainer {
  text-align: left;
  padding-left: 10px;
  width: fit-content;
  color: $BlackGrey;

  & > div {
    vertical-align: middle;
    display: inline;
  }
}

.actionBtnContainer:hover {
  cursor: pointer;
  color: $CrooglooGreen;
}

.scriptList {
  width: 100%;
  height: calc(100vh - #{$headerHeight} - #{$tabHeaderHeight});
}

.listItem {
 border: 1px solid $WhiteGrey;
 min-height: 58px;
}

.listItem:hover {
  background-color: $WhiteGrey;
}

.currentListItem {
  background-color: $WhiteGreen;
}

.selectedListItem {
  background-color: $WhiteGrey;
}

.itemText {
  text-align: left;
  text-transform: capitalize;
  color: $BlackGrey;
  font-weight: 400;
  font-size: 0.875rem;
}

.selectedItemText {
  color: $BorderBlack !important;
}