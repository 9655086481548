@import 'src/theme/colors';

.EditContact {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .contentWrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 5%;
  }

  .headingBox {
    display: flex;
    align-items: flex-start;
  }

  .heading {
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 45px;
    text-transform: capitalize;
    color: #222222;
    padding: 12px;
    float: left;
    height: 26px;
  }

  .contentLeft {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    gap: 10px;
    flex: 1;
  }

  .buttonRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .backButton {
    display: flex;
    flex: 1;
    justify-content: flex-start;
  }

  .smallSaveButton {
    width: 170px;
  }

  .contentRight {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
  }

  label {
    text-align: left;
  }

  .closeButton:hover {
    background-color: transparent;
  }

  .inviteToCroogloo {
    width: 182px;

    .securityGroupsButton {
      background-color: #DFF7DF;
    }
  }

  .topButton {
    height: 36px;
  }

  .spinner {
    margin: 32px;
  }

  @media screen and (max-width: 992px) {
    .inviteToCroogloo {
      width: 170px;
    }

    .topButton {
      padding: 8px 15px;
    }

    .smallSaveButton {
      width: 120px;
    }
  }

  @media screen and (max-width: 768px) {
    .contentWrapper {
      flex-direction: column;
    }
  }
}