@import 'src/theme/colors';
.SearchFilesFlow {
  text-align: center;

  .searchFilesFlowPaper {
    max-width: 538px;
    max-height: calc(100vh - 100px);
    overflow-y: hidden;
  }

  .header {
    color: $BorderBlack;
    font-size: 1.5rem;
    font-weight: 700;
  }

  .listContent {
    max-width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-height: calc(100vh - 350px);
    padding: 0px;
  }
}